<template>
    <div>
      <bank-comp :type="type"></bank-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {checkPermission} from "@/core/helpers/general";
import BankComp from "@/components/admin/bank/BankComp";

export default defineComponent({
    name: "edit-bank",
    components : {BankComp},

    setup() {

        const type = ref('edit');
        onMounted(() => {
            setCurrentPageBreadcrumbs("Update Bank", ["Banks"] , checkPermission('banks.store') ? 'create-bank' : null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
